import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "components/Layout"
import WeightLossBanner from "components/Banners/WeightLossBanner"
import WeightLossCoaching from "../components/WeightLossCoaching"
import WeightLossOffering from "../components/WeightLossOffering"
import WeightLossGetStarted from "../components/WeightLossGetStarted"
import WeightLossHealthyExample from "../components/WeightLossHealthyExample"
import WeightLossMedical from "../components/WeightLossMedical"
import Signup from "components/Signup"
import Seo from "components/Seo"

const getImages = graphql`
  {
    bannerWeightLoss: file(
      relativePath: { eq: "fitfarm-omaha-weightloss-and-nutrition-export.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    weightLossCoaching: file(
      relativePath: { eq: "fitfarm-omaha-weightloss-and-nutrition-2.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weightLossOffering: file(
      relativePath: { eq: "fitfarm-omaha-weightloss-and-nutrition-4.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weightLossGetStarted: file(
      relativePath: { eq: "fitfarm-omaha-weightloss-and-nutrition-1.webp" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    weightLossHealthyExample: file(
      relativePath: { eq: "fitfarm-omaha-weightloss-and-nutrition-5.webp" }
    ) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    signup: file(
      relativePath: { eq: "fitfarm-midtown-gym-omaha-interior.png" }
    ) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const PersonalTraining = () => {
  const imgData = useStaticQuery(getImages)

  return (
    <Layout>
      <Seo
        title="Growing Omaha Weight Loss and Nutrition"
        description="At FitFarm our Omaha Weight Loss and Nutrition Program is made for you and your goals! In person and online coaching available."
        keywords={[
          "weight loss omaha",
          "omaha weight loss",
          "weight loss",
          "omaha nutrition",
          "nutrition",
          "nutrition coach",
        ]}
        pathname={"/weightloss-and-nutrition/"}
      />
      <article>
        <WeightLossBanner leftImage={imgData.bannerWeightLoss} />
        <WeightLossCoaching sectionImage={imgData.weightLossCoaching} />
        <WeightLossGetStarted sectionImage={imgData.weightLossGetStarted} />
        <WeightLossOffering sectionImage={imgData.weightLossOffering} />
        <WeightLossHealthyExample
          sectionImage={imgData.weightLossHealthyExample}
        />
        <WeightLossMedical />
        <Signup sectionImage={imgData.signup} />
      </article>
    </Layout>
  )
}

export default PersonalTraining
